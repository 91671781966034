/** Ends and Odds: Pinepoint Memories **/

import React from "react";
import { gsap } from "gsap"
import FullscreenVideo from "../../components/media/fullscreen-video"
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"


let globalState, data, preloadData;

class EndsAndOddsPage2 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[2];
    preloadData = globalState.assets[3];

    //refs
    this.bgVideoRef = this.planeLandingVideoRef = this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
  }

  
  

  componentDidMount = () => {

    console.log("VIDEO URL: " + globalState.videoUrl + data.bgVideo);

    setTimeout(()=>{
      this.bgVideoRef.playVideo();
    }, globalState.transitionDuration);

    //position text
    positionText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
    positionText(this.refs.text2Ref, "right", "bottom", globalState.textPosition.rightBottom);
    

    //show text
    gsap.to(this.refs.text1Ref, 0, { autoAlpha: 1.0 });
    gsap.to(this.refs.text2Ref, 0, { autoAlpha: 1.0 });
    
    //update base track volume
    globalState.baseAudioRef.updateVolume(1, 15);
    
    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.background, 
      globalState.cdnUrl + preloadData.badge1,
      globalState.cdnUrl + preloadData.badge2,
      globalState.cdnUrl + preloadData.badge3,
      globalState.cdnUrl + preloadData.badge4,
      globalState.cdnUrl + preloadData.badge5,
      globalState.cdnUrl + preloadData.badge6,
      globalState.cdnUrl + preloadData.badge7,
      globalState.cdnUrl + preloadData.badge8,
      globalState.cdnUrl + preloadData.badge9,
      globalState.cdnUrl + preloadData.badge10,
      globalState.cdnUrl + preloadData.badge11,
      globalState.cdnUrl + preloadData.badge12,
      globalState.cdnUrl + preloadData.badge13,
      globalState.cdnUrl + preloadData.badge14,
      globalState.cdnUrl + preloadData.badge15,
      globalState.cdnUrl + preloadData.badge16,
      globalState.cdnUrl + preloadData.badge17,
      globalState.cdnUrl + preloadData.badge18,
      globalState.cdnUrl + preloadData.badge19,
      globalState.cdnUrl + preloadData.badge20,
      globalState.cdnUrl + preloadData.badge21,
      globalState.cdnUrl + preloadData.badge22,
      globalState.cdnUrl + preloadData.badge23,
      globalState.cdnUrl + preloadData.badge24,
      globalState.cdnUrl + preloadData.badge25,
      globalState.cdnUrl + preloadData.badge26,
      globalState.cdnUrl + preloadData.badge27,
      globalState.cdnUrl + preloadData.badge28,
      globalState.cdnUrl + preloadData.badge29,
      globalState.cdnUrl + preloadData.badge30,
      globalState.cdnUrl + preloadData.badge31,
      globalState.cdnUrl + preloadData.badge32,
      globalState.cdnUrl + preloadData.badge33,
      globalState.cdnUrl + preloadData.badge34,
      globalState.cdnUrl + preloadData.badge35,
      globalState.cdnUrl + preloadData.badge36,
      globalState.cdnUrl + preloadData.badge37,
      globalState.cdnUrl + preloadData.badge38,
      globalState.cdnUrl + preloadData.badge39,
      globalState.cdnUrl + preloadData.badge40,
      globalState.cdnUrl + preloadData.badge41
    ], this.preloadCallback);

  }

  preloadCallback = () =>{
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }

  

  componentWillUnmount() {
    this.bgVideoRef.pauseVideo();
    
    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);
    gsap.killTweensOf(this.refs.text2Ref);

    
  }

  videoEnded = () => {
    console.log("Bg Video ended");
    
    //update base track volume
    globalState.baseAudioRef.updateVolume(1, 1);
    //this.bgVideoRef.seekVideo(30);
  }

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({textVisible: !this.state.textVisible});

    //reset the animation delay
    resetAnimationDelay();

    if(this.state.textVisible){
      //show text
      showText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
      showText(this.refs.text2Ref, "right", "bottom", globalState.textPosition.rightBottom);
      

    } else {
      //hide text
      hideText(this.refs.text1Ref, "left");
      hideText(this.refs.text2Ref, "right");
    }
  }

  isTransitioning = () =>{
    this.bgVideoRef.pauseVideo();
  }


  render() {

    return (

      <>

        <link rel="prefetch" href="/ends-and-odds/3" />
        
        <PrevNext globalState={globalState} ref="prevNextRef" 
        nextLocation="/ends-and-odds/3" prevLocation="/ends-and-odds/1"
        isTransitioning={this.isTransitioning} />

        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Ends and Odds" />

          <div className="text-wrapper">
            <div className="text text--left-top" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginLeft: "0", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="There is a concentrated sadness in mementoes; they commemorate something that might never be again. A baby blanket, or that one seemingly inconsequential object that you can’t bear to get rid of even though all it does is sit at the bottom of a box. For me, it’s a candleholder my dad made – the only thing that would make me sad if it broke." />
            </div>
            <div className="text text--right-bottom" ref="text2Ref">
              <input type="image" src={globalState.cdnUrl + data.text2} style={{ marginRight: "60px", marginBottom: "160px" }} onClick={this.toggleTextDisplay} alt="The mine’s owners produced a video, ‘Pine Point Memories.’ Each resident was offered a copy." />
            </div>
            
          </div>

          {data.bgVideo ? 
            <FullscreenVideo file={globalState.videoUrl + data.bgVideo} 
            poster={globalState.cdnUrl + data.bgVideoPoster} autoPlay={false} loop={true} ended={this.videoEnded} ref={div => this.bgVideoRef = div} fullscreenClass={'fullscreen-video'} globalState={globalState} /> : ''
          }
          
        </div>
        )}
      </>
    );
  }


};

export default EndsAndOddsPage2
